import axios from 'axios';
import { baseURL } from '../baseURL';
import Cookies from 'js-cookie';

export const getReportTurkey = async (form) => {
    try {
        const response = await axios.post(`${baseURL}amantrans/report/turkey`, form, {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
            },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName =
            response.headers['content-disposition']?.split('filename=')[1] || 'report.xlsx';

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const getReportChina = async (form) => {
    try {
        const response = await axios.post(`${baseURL}amantrans/report/china`, form, {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
            },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName =
            response.headers['content-disposition']?.split('filename=')[1] || 'report.xlsx';

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const getChinaParameters = async () => {
    const { data } = await axios.get(`${baseURL}amantrans/report-parameters/china`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
};

export const getTurkeyParameters = async () => {
    const { data } = await axios.get(`${baseURL}amantrans/report-parameters/turkey`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
};
