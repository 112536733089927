import { Button, Select, Typography } from 'antd';
import { useFilias } from '../../../../api/common/common-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { UserInfoContext } from '../index';
import { Option } from 'antd/es/mentions';
import useCreateUser from '../../../../api/auth/auth-hooks';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/check.svg';

export default function FilialStep() {
    const [filial, setFilial] = useState();
    const { mutate, isSuccess } = useCreateUser();
    const context = useContext(UserInfoContext);
    const { data } = useFilias(context.user.cityId);
    const navigate = useNavigate();
    const handleChange = (e) => {
        setFilial(e);
    };

    const number = context.user.phone;
    useEffect(() => {
        if (data?.content?.length === 0) {
            mutate({
                username: '',
                phone: number,
                password: context.user.password,
                smsCode: '',
                cityId: context.user.cityId,
                filialId: 2,
                address: '',
                house: '',
            });
        }
    }, [data, mutate, number, context.user.password, context.user.cityId]);
    const onNext = () => {
        mutate({
            username: '',
            phone: number,
            password: context.user.password,
            smsCode: '',
            cityId: context.user.cityId,
            filialId: filial,
            address: '',
            house: '',
        });
    };

    const handleNavigate = () => {
        if (isSuccess && filial === 200) {
            navigate('/client-main');
        }
    };
    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                Выберите филиал
            </Typography.Title>
            <Select
                style={{
                    width: '100%',
                    height: '52px',
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    borderRadius: '8px',
                }}
                onChange={handleChange}
            >
                {data &&
                    data.content?.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
            </Select>
            <div className={'logo-div'}>
                <Logo width={'100%'} height={'100%'} />
            </div>
            <Button
                style={{
                    width: '100%',
                    height: '52px',
                    backgroundColor: 'white',
                    marginTop: '20px',
                }}
                onClick={onNext}
            >
                <Link to={'/client-main'} onClick={handleNavigate}>
                    <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                        Продолжить
                    </Typography.Text>
                </Link>
            </Button>
        </>
    );
}
