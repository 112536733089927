import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './styles.module.css';
import initial from '../../assets/aman-horse.png';
import { useNavigate } from 'react-router-dom';
import { CubeIcons, FaceIcons, QuestionsIcons, TarifIcons } from '../../icons';
import { jwtDecode } from 'jwt-decode';
import { ReactComponent as DeletedCargoIcon } from '../../assets/deleted-cargo-icon.svg';
import { ReactComponent as DeletedUsersIcon } from '../../assets/deleted-users-icon.svg';
import Cookies from 'js-cookie';
import { TurkeyIconPlane } from '../../icons/info-page-icons/TurkeyIconPlane';
import { ChinaIconPlane } from '../../icons/info-page-icons/ChinaIconPlane';

const UserInfo = ({ translation, city, role }) => (
    <div className={s['content-info']}>
        <div className={s['content-div']}>
            <img className={s['content-img']} src={initial} alt="initial" />
            <div className={s['line']} />
            <p className={s['content-group']}>
                {translation('cityName')}: {city}
            </p>
            <p className={s['content-group']}>
                {role === 'RoleLeadAdmin' ? 'Роль: Лид Админ' : ''}
            </p>
        </div>
    </div>
);

const LanguageSwitcher = ({ language, changeToRussian, changeToKazakh, changeToChina }) => {
    const langStyles = useMemo(
        () => ({
            ru: {
                cursor: 'pointer',
                color: language === 'ru' ? 'rgba(16, 14, 113, 1)' : 'rgba(177, 179, 182, 1)',
            },
            kz: {
                cursor: 'pointer',
                color: language === 'kz' ? 'rgba(16, 14, 113, 1)' : 'rgba(177, 179, 182, 1)',
            },
            ch: {
                cursor: 'pointer',
                color: language === 'ch' ? 'rgba(16, 14, 113, 1)' : 'rgba(177, 179, 182, 1)',
            },
        }),
        [language],
    );

    return (
        <div className={s['div-change-language']}>
            <p
                className={`${s['style-change-language']}`}
                onClick={changeToRussian}
                style={langStyles.ru}
            >
                Рус
            </p>
            <div className={s['dot']} />
            <p
                className={`${s['style-change-language']}`}
                onClick={changeToKazakh}
                style={langStyles.kz}
            >
                Қаз
            </p>
            <p
                className={`${s['style-change-language']}`}
                onClick={changeToChina}
                style={langStyles.ch}
            >
                中国
            </p>
        </div>
    );
};

const ClientLinks = ({
    handleLinkClick,
    activeLink,
    translation,
    changeToRussian,
    changeToKazakh,
    language,
    changeToChina,
}) => (
    <div className={s['role-client']}>
        <div className={s['type']}>
            {[
                {
                    path: '/client-main',
                    icon: <CubeIcons styleSVG="style-cube-svg" />,
                    text: translation('mainPage'),
                },
                {
                    path: '/my-orders',
                    icon: <ChinaIconPlane styleSVG="style-cube-svg" />,
                    text: translation('ordersFromChina'),
                },
                {
                    path: '/my-orders-turkey',
                    icon: <TurkeyIconPlane styleSVG="style-cube-svg" />,
                    text: translation('ordersFromTurkey'),
                },
                {
                    path: '/my-profile',
                    icon: <FaceIcons styleSVG="style-cube-svg" />,
                    text: translation('profile'),
                },
                {
                    path: '/rates',
                    icon: <TarifIcons styleSVG="style-cube-svg" />,
                    text: translation('rates'),
                },
                {
                    path: '/client-main/statuses',
                    icon: <QuestionsIcons styleSVG="style-cube-svg" />,
                    text: translation('questionHelper'),
                },
            ].map((link) => (
                <div
                    key={link.path}
                    onClick={() => handleLinkClick(link.path)}
                    className={`${s['type-div']} ${activeLink === link.path ? s['active'] : ''}`}
                >
                    {link.icon}
                    <p className={s['type-text']}>{link.text}</p>
                </div>
            ))}
            <div className={s['line-2']} />
            <LanguageSwitcher
                language={language}
                changeToChina={changeToChina}
                changeToRussian={changeToRussian}
                changeToKazakh={changeToKazakh}
            />
        </div>
    </div>
);

const AdminLinks = ({
    handleLinkClick,
    activeLink,
    translation,
    changeToRussian,
    changeToKazakh,
    logout,
    language,
    changeToChina,
}) => (
    <div className={s['role-admin']}>
        <div className={s['type']}>
            {[
                {
                    path: '/admin-main',
                    icon: <TarifIcons styleSVG="style-cube-svg" />,
                    text: translation('static'),
                },
                {
                    path: '/my-cargo',
                    icon: <ChinaIconPlane styleSVG="style-cube-svg" />,
                    text: translation('chinaCargos'),
                },
                {
                    path: '/turkey-cargo',
                    icon: <TurkeyIconPlane styleSVG="style-cube-svg" />,
                    text: translation('turkeyCargos'),
                },
                {
                    path: '/my-customers',
                    icon: <FaceIcons styleSVG="style-cube-svg" />,
                    text: translation('users-admin'),
                },
                {
                    path: '/branches',
                    icon: <FaceIcons styleSVG="style-cube-svg" />,
                    text: translation('branches'),
                },
                {
                    path: '/deleted-users',
                    icon: <DeletedUsersIcon styleSVG="style-cube-svg" />,
                    text: translation('deletedUsers'),
                },
                {
                    path: '/deleted-cargo',
                    icon: <DeletedCargoIcon styleSVG="style-cube-svg" />,
                    text: translation('deletedCargo'),
                },
                {
                    path: '/rates',
                    icon: <FaceIcons styleSVG="style-cube-svg" />,
                    text: translation('rates'),
                },
                {
                    path: '/lost-items',
                    icon: <FaceIcons styleSVG="style-cube-svg" />,
                    text: translation('lostItems'),
                },
                {
                    path: '/client-main/statuses',
                    icon: <QuestionsIcons styleSVG="style-cube-svg" />,
                    text: translation('questionHelper'),
                },
            ].map((link) => (
                <div
                    key={link.path}
                    onClick={() => handleLinkClick(link.path)}
                    className={`${s['type-div']} ${activeLink === link.path ? s['active'] : ''}`}
                >
                    {link.icon}
                    <p className={s['type-text']}>{link.text}</p>
                </div>
            ))}
        </div>
        <div className={s['line-2']} />
        <div className={s['settings']}>
            <LanguageSwitcher
                changeToChina={changeToChina}
                language={language}
                changeToRussian={changeToRussian}
                changeToKazakh={changeToKazakh}
            />
            <div className={s['logout-div']}>
                <p onClick={logout} className={s['logout-text']}>
                    {translation('logout')}
                </p>
            </div>
        </div>
    </div>
);

const UchetLinks = ({
    handleLinkClick,
    activeLink,
    translation,
    changeToRussian,
    changeToKazakh,
    language,
    logout,
    changeToChina,
}) => (
    <div className={s['role-client']}>
        <div className={s['type']}>
            {[
                // {
                //     path: '/client-main',
                //     icon: <CubeIcons styleSVG="style-cube-svg" />,
                //     text: translation('mainPage'),
                // },
                {
                    path: '/report',
                    icon: <TarifIcons styleSVG="style-cube-svg" />,
                    text: translation('report'),
                },
            ].map((link) => (
                <div
                    key={link.path}
                    onClick={() => handleLinkClick(link.path)}
                    className={`${s['type-div']} ${activeLink === link.path ? s['active'] : ''}`}
                >
                    {link.icon}
                    <p className={s['type-text']}>{link.text}</p>
                </div>
            ))}
            <div className={s['line-2']} />
            <LanguageSwitcher
                changeToChina={changeToChina}
                language={language}
                changeToRussian={changeToRussian}
                changeToKazakh={changeToKazakh}
            />
            <div className={s['logout-div']}>
                <p onClick={logout} className={s['logout-text']}>
                    {translation('logout')}
                </p>
            </div>
        </div>
    </div>
);

const InfoPage = ({
    closeMenu,
    translation,
    handleLenguageChange,
    language,
    changeToRussian,
    changeToKazakh,
    changeToChina,
}) => {
    const [role, setRole] = useState(null);
    const [activeLink, setActiveLink] = useState(localStorage.getItem('activeLink') || '');
    const navigate = useNavigate();
    const [cityName, setCityName] = useState(null);
    const [userId, setUserId] = useState(null);
    useEffect(() => {
        const token = Cookies.get('token');
        if (token && typeof token === 'string') {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.role);
                setUserId(decodedToken.id);
                setCityName(decodedToken.cityName);
            } catch (error) {
                setRole('');
            }
        } else {
            setRole('');
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('activeLink', activeLink);
    }, [activeLink]);

    const onLogout = useCallback(() => {
        Cookies.remove('token');
        navigate('/');
    }, [navigate]);

    const handleLinkClick = useCallback(
        (path) => {
            setActiveLink(path);
            navigate(path);
            if (closeMenu) closeMenu();
        },
        [closeMenu, navigate],
    );
    if (role === null) {
        return <div>Loading...</div>;
    }

    const getContentByRole = () => {
        switch (role) {
            case 'RoleAdmin':
            case 'RoleLeadAdmin':
            case 'RoleAlmaty':
            case 'RoleChina':
                return (
                    <AdminLinks
                        logout={onLogout}
                        activeLink={activeLink}
                        changeToKazakh={changeToKazakh}
                        changeToRussian={changeToRussian}
                        handleLinkClick={handleLinkClick}
                        translation={translation}
                        role={role}
                        handleLenguageChange={handleLenguageChange}
                        language={language}
                        changeToChina={changeToChina}
                    />
                );
            case 'RoleUchet':
                return (
                    <UchetLinks
                        logout={onLogout}
                        activeLink={activeLink}
                        changeToKazakh={changeToKazakh}
                        changeToRussian={changeToRussian}
                        handleLenguageChange={handleLenguageChange}
                        language={language}
                        handleLinkClick={handleLinkClick}
                        translation={translation}
                        changeToChina={changeToChina}
                    />
                );
            case 'RoleClient':
            default:
                return (
                    <ClientLinks
                        activeLink={activeLink}
                        changeToKazakh={changeToKazakh}
                        changeToRussian={changeToRussian}
                        handleLenguageChange={handleLenguageChange}
                        language={language}
                        handleLinkClick={handleLinkClick}
                        translation={translation}
                        changeToChina={changeToChina}
                    />
                );
        }
    };

    return (
        <div className={s['container']}>
            <UserInfo translation={translation} city={cityName} role={role} />
            {getContentByRole()}
        </div>
    );
};

export default InfoPage;
