import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { AddressIcon, EmailIcon, FaceIcon, IDIcon, PhoneIcon } from '../../icons';
import PhoneForm from './forms/PhoneForm';
import EmailForm from './forms/EmailForm';
import AddressForm from './forms/AddressForm';
import IDForm from './forms/IDForm';
import s from './styles.module.css';
import { useUserDetail } from '../../api/auth/auth-hooks';
import { Link, useNavigate } from 'react-router-dom';
import PasswordForm from './forms/PasswordForm';
import FilialForms from './forms/FilialForms';
import Cookies from 'js-cookie';
import SelectItem from './select-item';
import NameForm from './forms/NameForm';

const MyProfilePage = ({
    t,
    language,
    changeToRussian,
    changeToKazakh,
    roleID,
    push,
    fullURL,
    stopPushing,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState('password');
    const { data, refetch } = useUserDetail();
    const [copied, setCopied] = useState(false);
    const [copiedTurkey, setCopiedTurkey] = useState(false);
    const navigate = useNavigate();
    const onOpen = (formType) => {
        setIsOpen(true);
        setForm(formType);
    };

    const renderForm = () => {
        switch (form) {
            case 'name':
                return <NameForm refretch={refetch} closeModal={() => setIsOpen(false)} />;

            case 'phone':
                return <PhoneForm refretch={refetch} closeModal={() => setIsOpen(false)} />;
            case 'email':
                return <EmailForm refretch={refetch} closeModal={() => setIsOpen(false)} />;
            case 'address':
                return (
                    <AddressForm
                        refretch={() => refetch()}
                        data={data}
                        closeModal={() => setIsOpen(false)}
                    />
                );
            case 'iin':
                return (
                    <IDForm
                        refretch={() => refetch()}
                        data={data}
                        closeModal={() => setIsOpen(false)}
                    />
                );
            case 'filial':
                return (
                    <FilialForms refretch={() => refetch()} closeModal={() => setIsOpen(false)} />
                );
            case 'password':
                return <PasswordForm data={data} />;
            default:
                return <div />;
        }
    };

    const onLogout = () => {
        console.log(push);
        // stopPushing(push, roleID);
        if (push !== null) {
            stopPushing(push, roleID);
        }
        Cookies.remove('token');
        navigate('/');
    };
    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
        }
        return phoneNumber;
    };
    const handleCopy = () => {
        const idCode = data?.idCode?.toString();
        const textToCopy = `${idCode?.toUpperCase()}\n${data?.chinaWarehousePhone}${data?.chinaWarehouse}(${idCode?.toLowerCase()})`;

        if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.copyToClipboard
        ) {
            window.webkit.messageHandlers.copyToClipboard.postMessage(textToCopy);
        } else if (window.AndroidInterface && window.AndroidInterface.copyToClipboard) {
            window.AndroidInterface.copyToClipboard(textToCopy);
        } else {
            const tempInput = document.createElement('textarea');
            tempInput.value = textToCopy;
            document.body.appendChild(tempInput);
            tempInput.select();
            try {
                document.execCommand('copy');
                setCopied(true);
                setTimeout(() => setCopied(false), 1500);
            } catch (err) {
                console.error('Could not copy text: ', err);
            }
            document.body.removeChild(tempInput);
        }
    };

    const handleCopyTurkey = () => {
        const textToCopy = ` ${data?.idCode}\n${data?.turkeyWarehouse}\n${data?.turkeyWarehousePhone}`;

        if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.copyToClipboard
        ) {
            window.webkit.messageHandlers.copyToClipboard.postMessage(textToCopy);
        } else if (window.AndroidInterface && window.AndroidInterface.copyToClipboard) {
            window.AndroidInterface.copyToClipboard(textToCopy);
        } else {
            const tempInput = document.createElement('textarea');
            tempInput.value = textToCopy;
            document.body.appendChild(tempInput);
            tempInput.select();
            try {
                document.execCommand('copy');
                setCopiedTurkey(true);
                setTimeout(() => setCopiedTurkey(false), 1500);
            } catch (err) {
                console.error('Could not copy text: ', err);
            }
            document.body.removeChild(tempInput);
        }
    };

    return (
        <div className={s['container']}>
            <div className={s['content']}>
                <Modal open={isOpen} onCancel={() => setIsOpen(false)} footer={false}>
                    {renderForm()}
                </Modal>
                <Typography.Title
                    style={{
                        color: '#100E71',
                        fontFamily: 'Roboto, san-serif',
                        margin: '0',
                        padding: '0',
                        marginLeft: '44px',
                        marginTop: '53px',
                    }}
                    className={s['my-profile-title']}
                >
                    {t('myProfile')}
                </Typography.Title>

                <div className={s['my-profile-list']}>
                    <div className={s['my-profile-header']}>
                        <Typography.Title
                            style={{
                                color: '#100E71',
                                margin: '0',
                                marginLeft: '25px',
                                fontFamily: 'Roboto, san-serif',
                                fontWeigh: '700',
                                fontSize: '18px',
                            }}
                            className={s['my-profile-title-mobile']}
                            level={5}
                        >
                            {t('yourData')}
                        </Typography.Title>
                        <Link className={s['link-help']} to={'/client-main/statuses'}>
                            {t('questionHelper')}
                        </Link>
                    </div>

                    <div>
                        {roleID === 21404 ||
                        roleID === 30166 ||
                        roleID === 30010 ||
                        roleID === 30087 ? (
                            <p
                                style={{
                                    backgroundColor: 'red',
                                    width: '20%',
                                    height: 'auto',
                                    wordWrap: 'break-word',
                                }}
                            >
                                `Наш Токен ${push} Full Url ${fullURL}`
                            </p>
                        ) : (
                            ''
                        )}
                        <SelectItem
                            title={t('fullNameWithLastName')}
                            value={formatPhoneNumber(`${data?.fullName}  ${data?.lastName}` || '')}
                            icon={<FaceIcon />}
                            onOpen={() => onOpen('name')}
                        />
                        <SelectItem
                            title={t('phone')}
                            value={formatPhoneNumber(data?.phone || '')}
                            icon={<PhoneIcon />}
                            onOpen={() => onOpen('phone')}
                        />
                        <SelectItem
                            title={t('email')}
                            value={data?.email || ''}
                            icon={<EmailIcon />}
                            onOpen={() => onOpen('email')}
                        />
                        <SelectItem
                            title={t('residentialAddress')}
                            value={`${data?.customerCityName || ''}, ${data?.customerStreet || ''} ${data?.customerHouse || ''}`}
                            icon={<AddressIcon />}
                            onOpen={() => onOpen('address')}
                        />
                        {data?.iin ? (
                            <div className={s['select-item']}>
                                <SelectItem
                                    title={t('iin')}
                                    value={data?.iin || ''}
                                    icon={<IDIcon />}
                                />
                            </div>
                        ) : (
                            <SelectItem
                                title={t('iin')}
                                value={data?.iin || ''}
                                icon={<IDIcon />}
                                onOpen={() => onOpen('iin')}
                            />
                        )}

                        {/*<SelectItem*/}
                        {/*    title={t('filialAddress')}*/}
                        {/*    value={data?.filialAddress || ''}*/}
                        {/*    icon={<FilialIcon />}*/}
                        {/*    onOpen={() => onOpen('filial')}*/}
                        {/*/>*/}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={s['button-div']}>
                                <Button
                                    style={{
                                        backgroundColor: '#100E71',
                                        width: '145px',
                                        color: 'white',
                                        borderRadius: '24px',
                                    }}
                                    onClick={() => onOpen('password')}
                                >
                                    {t('changePassword')}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: '#F53226',
                                        width: '145px',
                                        color: 'white',
                                        borderRadius: '24px',
                                    }}
                                    onClick={onLogout}
                                >
                                    {t('logout')}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: '#F53226',
                                        width: '145px',
                                        color: 'white',
                                        borderRadius: '24px',
                                    }}
                                    onClick={onLogout}
                                >
                                    {t('deleteAccount')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s['my-profile-list']}>
                    <Typography.Title level={4} style={{ margin: '10px' }}>
                        {t('myAddressID')}
                    </Typography.Title>
                    <div
                        style={{
                            border: '#D6DADF 1px solid',
                            borderRadius: '10px',
                            padding: '20px',
                            margin: '0 10px 20px 10px',
                        }}
                    >
                        <Typography.Text style={{ margin: '10px' }}>
                            {data?.idCode || ''}
                        </Typography.Text>
                    </div>
                </div>
                <div className={s['my-profile-list']} style={{ marginBottom: '2%' }}>
                    <Typography.Title level={4} style={{ margin: '10px' }}>
                        {t('myAddressChina')}
                    </Typography.Title>
                    <div
                        style={{
                            border: '#D6DADF 1px solid',
                            borderRadius: '10px',
                            padding: '20px',
                            margin: '0 10px 20px 10px',
                        }}
                    >
                        <Typography.Text style={{ margin: '10px' }}>
                            {data?.chinaWarehouse || ''} ({data?.idCode || ''})
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            border: '#D6DADF 1px solid',
                            borderRadius: '10px',
                            padding: '20px',
                            margin: '0 10px 20px 10px',
                        }}
                    >
                        <Typography.Text style={{ margin: '10px' }}>
                            {data?.chinaWarehousePhone || ''}
                        </Typography.Text>
                    </div>
                    <div className={s['div-button-china']}>
                        {copied && (
                            <Typography.Text style={{ color: '#100E71', marginBottom: '10px' }}>
                                Текст скопирован
                            </Typography.Text>
                        )}
                        <Button
                            style={{
                                backgroundColor: '#100E71',
                                width: '145px',
                                color: 'white',
                                padding: '8px 10px',
                                border: 'none',
                                borderRadius: '24px',
                            }}
                            onClick={handleCopy}
                        >
                            {t('copyText')}
                        </Button>
                    </div>
                </div>
                <div className={s['my-profile-list']} style={{ marginBottom: '10%' }}>
                    <Typography.Title level={4} style={{ margin: '10px' }}>
                        {t('myAddressTurkey')}
                    </Typography.Title>
                    <div
                        style={{
                            border: '#D6DADF 1px solid',
                            borderRadius: '10px',
                            padding: '20px',
                            margin: '0 10px 20px 10px',
                        }}
                    >
                        <Typography.Text style={{ margin: '10px' }}>
                            {data?.turkeyWarehouse || ''}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            border: '#D6DADF 1px solid',
                            borderRadius: '10px',
                            padding: '20px',
                            margin: '0 10px 20px 10px',
                        }}
                    >
                        <Typography.Text style={{ margin: '10px' }}>
                            {data?.turkeyWarehousePhone || ''}
                        </Typography.Text>
                    </div>
                    <div className={s['div-button-china']}>
                        {copiedTurkey && (
                            <Typography.Text style={{ color: '#100E71', marginBottom: '10px' }}>
                                Текст скопирован
                            </Typography.Text>
                        )}
                        <Button
                            style={{
                                backgroundColor: '#100E71',
                                width: '145px',
                                color: 'white',
                                padding: '8px 10px',
                                border: 'none',
                                borderRadius: '24px',
                            }}
                            onClick={handleCopyTurkey}
                        >
                            {t('copyText')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MyProfilePage;
