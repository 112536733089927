import React, { useContext, useState } from 'react';
import { UserInfoContext } from '../index';

import { Button, Input, Typography } from 'antd';
import s from '../styles.module.css';
import useCreateUser from '../../../../api/auth/auth-hooks';
import { ReactComponent as Logo } from '../../../../assets/check.svg';

export default function SmsCodeForm({ setIsNextStep }) {
    const [smsCode, setSmsCode] = useState('');
    const { mutate } = useCreateUser();
    const context = useContext(UserInfoContext);
    const number = context.user.phone;
    const onChange = (e) => {
        setSmsCode(e.target.value);
    };
    const onNext = () => {
        context.setUser({
            username: '',
            phone: number,
            password: '',
            smsCode,
            cityId: '',
            filialId: '',
            address: '',
            house: '',
        });
        mutate({
            username: '',
            phone: number,
            cityId: '',
            filialId: '',
            password: '',
            smsCode,
            address: '',
            house: '',
        });
        setIsNextStep('password');
    };

    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                Введите СМС-код
            </Typography.Title>
            <div style={{ textAlign: 'center' }}>
                <Typography.Text style={{ color: 'white', fontWeight: '700' }}>
                    Мы отправили код на номер
                </Typography.Text>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Typography.Text style={{ color: 'white' }}>
                    {context.user.phoneNumber}
                </Typography.Text>
            </div>
            <Input
                style={{
                    height: '52px',
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: '16px',
                    fontWeight: 400,
                    paddingLeft: '26px',
                    lineHeight: '24px',
                    textAlign: 'left',
                    borderRadius: '8px',
                }}
                placeholder="КОД"
                type={'number'}
                className={s['register-input']}
                onChange={onChange}
                autoComplete="one-time-code"
            />
            <div className={'logo-div'}>
                <Logo width={'100%'} height={'100%'} />
            </div>
            <Button
                style={{
                    width: '100%',
                    height: '52px',
                    backgroundColor: 'white',
                }}
                onClick={onNext}
            >
                <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                    Продолжить
                </Typography.Text>
            </Button>
        </>
    );
}
