import s from './styles.module.css';
import EnterIcons from '../../icons/order-turkey-info-icons/EnterIcons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHistoryStatusChina } from '../../api/order-turkey-info-api/order-turkey-info-api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import React, { useEffect, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import { axiosInstances } from '../../api/axios-instances';
import StatusColor from '../UI/statusColor/statusColor';
import { format } from 'date-fns';
import { kk, ru } from 'date-fns/locale';
import Cookies from 'js-cookie';
import CustomModal from '../UI/Modal/Modal';
import { jwtDecode } from 'jwt-decode';

const OrderChinaInfo = ({ t, language }) => {
    const [openModal, setOpenModal] = useState(false);
    const navigation = useNavigate();
    const location = useLocation();
    const [rowData, setRowData] = useState(location.state.rowData || {});
    const {
        data: statusData,
        error: ErrorStatus,
        isLoading: LoadingStatus,
    } = useQuery(['historyStatusChina', rowData.id], () =>
        getHistoryStatusChina({ id: rowData.id, country: 'China' }),
    );
    const [errors, setErrors] = useState({});
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [role, setRole] = useState(null);
    const token = Cookies.get('token');
    const queryClient = useQueryClient();
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.role);
            } catch (error) {
                setRole('');
            }
        } else {
            setRole('');
        }
    }, [location.pathname]);
    const updateOrder = useMutation({
        mutationFn: async (user, id) => {
            const response = await axiosInstances.put(`/amantrans/orders/update`, user, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['orders', rowData.id], (oldData) => ({
                ...oldData,
                ...data,
            }));
            setRowData((prevData) => ({
                ...prevData,
                ...data,
            }));
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const [inputData, setInputData] = useState({
        name: '',
        price: 0,
        amount: '',
        weight: 0,
        track_code: '',
    });
    const handleOpenModal = (row) => {
        setInputData({
            name: rowData.name,
            price: rowData.price,
            amount: rowData.amount,
            weight: rowData.weight,
            track_code: rowData.track_code,
        });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setInputData({
            name: '',
            price: 0,
            amount: '',
            weight: 0,
            track_code: '',
        });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const saveUserChanges = () => {
        const newErrors = {};
        const isValidWeight = /^(\d+([.,]\d{1,2})?)$/;
        Object.keys(inputData).forEach((key) => {
            if (!inputData[key] && key !== 'weight') {
                newErrors[key] = 'Поле не может быть пустым';
            }
        });
        if (role === 'RoleLeadAdmin' || role === 'RoleAdmin' || role === 'RoleChina') {
            let weight = inputData.weight;

            // Замена запятой на точку для корректной обработки
            if (typeof weight === 'string') {
                weight = weight.replace(',', '.');
                inputData.weight = parseFloat(weight);
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setShowErrorMessage(true);
            setTimeout(() => {
                setErrors({});
            }, 2000);
            return;
        }
        console.log(role);

        const updateData = {
            id: rowData.id,
            name: inputData.name,
            price: inputData.price,
            amount: inputData.amount,
            weight: inputData.weight,
            track_code: inputData.track_code,
        };

        updateOrder.mutate(updateData, {
            onSuccess: () => {
                setOpenModal(false);
                setInputData({
                    name: '',
                    price: 0,
                    amount: '',
                    weight: 0,
                    track_code: '',
                });
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };

    if (!rowData) {
        return <div>Нет данных для отображения</div>;
    }
    const date = new Date(rowData.created_at);
    if (LoadingStatus) return <Spinner />;
    if (ErrorStatus) return <div>There was an error loading orders!</div>;
    return (
        <div className={'container'}>
            <CustomModal visible={openModal} onClose={handleCloseModal}>
                <div className={s['modal-div']}>
                    <p className={s['modal-title']}>Редактировать заказ</p>
                    <div className={s['input-div']}>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.name ? s['input-error'] : ''}`}
                                name="name"
                                value={inputData.name}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.name ? s.filled : ''}`}
                            >
                                {t('writeOrderName')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                type="number"
                                className={`${s['input-modal']} ${errors.price ? s['input-error'] : ''}`}
                                name="price"
                                value={inputData.price}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.price ? s.filled : ''}`}
                            >
                                {t('writePrice')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                type="number"
                                className={`${s['input-modal']} ${errors.amount ? s['input-error'] : ''}`}
                                name="amount"
                                value={inputData.amount}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.amount ? s.filled : ''}`}
                            >
                                {t('writeAmount')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.track_code ? s['input-error'] : ''}`}
                                name="track_code"
                                value={inputData.track_code}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.track_code ? s.filled : ''}`}
                            >
                                {t('writeTrackCode')}
                            </label>
                        </div>
                        {role === 'RoleLeadAdmin' || role === 'RoleChina' ? (
                            <div className={s['input-container']}>
                                <input
                                    className={`${s['input-modal']} ${errors.weight ? s['input-error'] : ''}`}
                                    name="weight"
                                    value={inputData.weight}
                                    onChange={handleInputChange}
                                />
                                <label
                                    className={`${s['floating-label']} ${inputData.weight ? s.filled : ''}`}
                                >
                                    {t('weight')}
                                </label>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={s['modal-button']}>
                        <button className={s['button-modal']} onClick={handleCloseModal}>
                            {t('cancel')}
                        </button>
                        <button className={s['button-modal']} onClick={saveUserChanges}>
                            {t('save')}
                        </button>
                    </div>
                </div>
            </CustomModal>
            <header className={s['header']}>
                <div className={s['header-div']}>
                    <button onClick={() => navigation(-1)} className={s['enter-style']}>
                        <EnterIcons />
                    </button>
                    <div className={s['header-text']}>
                        {/*<p className={s['header-text-id']}>{t('idCode')} / {rowData.customer.id_code}</p>*/}
                        <p className={s['header-text-order']}>
                            {t('order')} {rowData.name}
                        </p>
                        <p
                            style={StatusColor(rowData.status.name_ru)}
                            className={s['header-text-new']}
                        >
                            {' '}
                            {language === 'ru' ? rowData.status.name_ru : rowData.status.name_kz}
                        </p>
                    </div>
                </div>
            </header>
            <div className={s['content']}>
                <div className={s['order-info']}>
                    <div className={s['title-div']}>
                        <p className={s['title-text']}>{t('orderInformation')}</p>
                    </div>
                    <div className={s['all-info']}>
                        {[
                            { label: t('orderName'), value: rowData.name },
                            // {label: t('idCode'), value: rowData.customer.id_code},
                            { label: t('orderName'), value: rowData.name || '' },
                            { label: t('trackCode'), value: rowData.track_code || '' },
                            { label: t('orderCreationDate'), value: date.toLocaleDateString() },
                            { label: t('buyer'), value: rowData.buyer_name || '' },
                            { label: t('amount'), value: rowData.amount || '' },
                            { label: t('orderSource'), value: rowData.order_source || '' },
                            {
                                label: t('tariffPrice'),
                                value: rowData.tariff_price ? `${rowData.tariff_price}$` : '',
                            },
                            { label: t('weight'), value: rowData.weight || '' },
                            { label: t('status'), value: rowData.status?.name_ru || '' },
                            { label: t('price'), value: rowData.price || '' },
                            {
                                label: t('boxPrice'),
                                value: rowData.box_price ? `${rowData.box_price}$` : '',
                            },
                            {
                                label: t('deliveryPrice'),
                                value: rowData.delivery_price ? `${rowData.delivery_price}$` : '',
                            },
                        ].map((item, index) => (
                            <div className={s['order-details']} key={index}>
                                <span className={s['label']}>{item.label}</span>
                                <span className={s['dots']}></span>
                                <span className={s['value']}>{item.value}</span>
                            </div>
                        ))}
                    </div>
                    <div className={s['tarif-div']}>
                        <div className={s['price-div']}>
                            <p className={s['tariff-style']}>{t('total')}</p>
                            <p className={s['tariff-price']}>
                                {(
                                    (rowData.delivery_price + rowData.box_price) *
                                    rowData.usd
                                ).toFixed(1)}
                                ₸
                            </p>
                        </div>
                        <div className={s['line-div']}></div>
                        <div className={s['price-div-tarif']}>
                            <p className={s['tariff-style']}>{t('tariff')}</p>
                            <p className={s['tariff-style-text']}>{rowData.tariff_price}$</p>
                        </div>
                    </div>
                    <div className={s['change-div']}>
                        <button onClick={() => handleOpenModal()} className={s['change-button']}>
                            {t('edit')}
                        </button>
                    </div>
                </div>
                <div className={s['history-div']}>
                    <div className={s['title-history-div']}>
                        <p className={s['title-history-style']}>{t('orderHistory')}</p>
                    </div>
                    {statusData?.map((row, key) => {
                        const date = new Date(row.update_date);
                        const formattedDate = format(date, 'd MMMM yyyy', {
                            locale: language === 'ru' ? ru : kk,
                        });
                        const formattedTime = format(date, 'HH:mm:ss', {
                            locale: language === 'ru' ? ru : kk,
                        });

                        return (
                            <div key={row.id}>
                                <div className={s['history-text-div']}>
                                    <div className={s['date-div']}>
                                        <p className={s['date-day']}>{formattedDate}</p>
                                        <p className={s['date-time']}>{formattedTime}</p>
                                    </div>
                                    <div className={s['history-status-div']}>
                                        <p
                                            style={StatusColor(row.status_to.name_ru)}
                                            className={s['history-status']}
                                        >
                                            {language === 'ru'
                                                ? row.status_to.name_ru
                                                : row.status_to.name_kz}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
export default OrderChinaInfo;
