import React, { useState, useEffect } from 'react';
import CustomModal from '../UI/Modal/Modal';
import s from './styles.module.css';
import { DatePicker, Checkbox } from 'antd';
import { ArrowIcon } from '../../assets';
import {
    getChinaParameters,
    getTurkeyParameters,
    getReportChina,
    getReportTurkey,
} from '../../api/report/report';
import { format } from 'date-fns';
import { getBranchList } from '../../api/admin-branch/functions';
import { getStatusTurkey } from '../../api/turkey-order-api/turkey-order-api';
import { fetchOrders } from '../../api/cargo-moderator-api/cargo-moderator-api';

const ReportPage = ({ t, language }) => {
    const { RangePicker } = DatePicker;

    const [selectedCountry, setSelectedCountry] = useState('china');

    const [sectionsList, setSectionsList] = useState([]);
    const [filialsList, setFilialsList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const [form, setForm] = useState({
        parameters: [],
        start_date: '2021-01-01',
        end_date: format(new Date(), 'yyyy-MM-dd'),
        status: '',
    });

    const [selectedModalType, setSelectedModalType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState('');

    const handleSectionsChecked = (section) => {
        setForm((prev) => ({
            ...prev,
            parameters: prev.parameters.includes(section)
                ? prev.parameters.filter((p) => p !== section)
                : [...prev.parameters, section],
        }));
    };

    const handleDateRangeChecked = (range) => {
        setForm((prev) => ({
            ...prev,
            start_date: format(new Date(range[0]), 'yyyy-MM-dd'),
            end_date: format(new Date(range[1]), 'yyyy-MM-dd'),
        }));
    };

    const handleSelect = (name, value) => {
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleConfirmSelectClick = () => {
        setIsModalOpen(false);
    };

    const handleParametersClick = (name) => {
        setSelectedModalType(name);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        if (selectedModalType === 'filialCol') {
            setForm((prev) => ({ ...prev, filial_id: 0 }));
        } else {
            setForm((prev) => ({ ...prev, status: '' }));
        }
        setIsModalOpen(false);
        setSelectedModalType('');
    };

    const handleDownloadClick = () => {
        const downloadReportApi = selectedCountry === 'china' ? getReportChina : getReportTurkey;
        if (form.filial_id === 0) {
            delete form.filial_id;
        }
        if (form.status === 'Все') {
            form.status = '';
        }
        downloadReportApi(form)
            .then((res) => console.log('res ', res))
            .catch((err) => console.log('err ', err))
            .finally(() => {
                setSelectedModalType('');
                setIsModalOpen(false);
            });
    };

    useEffect(() => {
        const getSections = selectedCountry === 'china' ? getChinaParameters : getTurkeyParameters;
        const getStatuses = selectedCountry === 'china' ? fetchOrders : getStatusTurkey;
        getSections()
            .then((res) => setSectionsList(res))
            .catch((err) => console.log('err ', err));
        getStatuses()
            .then((res) => setStatusList(res))
            .catch((err) => console.log('err ', err));
    }, [selectedCountry]);

    useEffect(() => {
        getBranchList().then((data) => setFilialsList(data));
    }, []);

    return (
        <div className={s['container']}>
            <h1 className={s['title']}>{t('report-finances')}</h1>
            <div className={s['tabs']}>
                <span
                    className={s[selectedCountry === 'turkey' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('turkey')}
                >
                    {t('ordersFromTurkey')}
                </span>
                <span
                    className={s[selectedCountry === 'china' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('china')}
                >
                    {t('china')}
                </span>
            </div>
            <div className={s['card']}>
                <h3 className={s['title2']}>{t('sortByDate')}</h3>
                <div className={s['withTop']}>
                    <RangePicker
                        onCalendarChange={handleDateRangeChecked}
                        size={'large'}
                        placeholder={[t('from'), t('to')]}
                        className={s['fullWidth']}
                        format={{
                            format: 'YYYY-MM-DD',
                            type: 'mask',
                        }}
                    />
                </div>
            </div>
            <h3 className={s['title2']}>{t('sections')}</h3>
            <div className={s['card']}>
                <div className={s['grid']}>
                    {sectionsList?.map((section) => (
                        <Checkbox
                            onChange={() => handleSectionsChecked(section.name)}
                            checked={form.parameters.includes(section.name)}
                        >
                            {language === 'ru' ? section.name_ru : section.name_kz}
                        </Checkbox>
                    ))}
                </div>
            </div>
            <h3 className={s['title2']}>{t('parameters')}</h3>
            <div className={s['card']}>
                <h3 className={s['title2']}>{t('filialCol')}</h3>
                <div
                    className={s['select-parameter']}
                    onClick={() => handleParametersClick('filialCol')}
                >
                    <span>
                        {form.filial_id === 0
                            ? 'Все'
                            : form.filial_id
                              ? filialsList.find((item) => item.id === form.filial_id)?.name
                              : t('selectFilials')}
                    </span>
                    <img src={ArrowIcon} alt="arrow" />
                </div>
                <h3 className={s['title2']}>{t('status')}</h3>
                <div
                    className={s['select-parameter']}
                    onClick={() => handleParametersClick('status')}
                >
                    <span>
                        {form.status === 'Все'
                            ? 'Все'
                            : form.status
                              ? statusList.find((item) => item.name === form.status)[
                                    language === 'ru' ? 'name_ru' : 'name_kz'
                                ]
                              : t('selectStatuses')}
                    </span>
                    <img src={ArrowIcon} alt="arrow" />
                </div>
            </div>
            <button className={s['primary-button']} onClick={handleDownloadClick}>
                {t('downloadFile')}
            </button>
            <CustomModal visible={isModalOpen}>
                <div className={s['modal-container']}>
                    <h3>{t(selectedModalType)}</h3>
                    <ul className={s['list']}>
                        {selectedModalType === 'filialCol' && (
                            <>
                                <li
                                    className={
                                        s[form.filial_id === 0 ? 'selected-item' : 'list-item']
                                    }
                                    onClick={() => handleSelect('filial_id', 0)}
                                >
                                    Выбрать все
                                </li>
                                {filialsList.map((item) => (
                                    <li
                                        className={
                                            s[
                                                form.filial_id === item.id
                                                    ? 'selected-item'
                                                    : 'list-item'
                                            ]
                                        }
                                        onClick={() => handleSelect('filial_id', item.id)}
                                    >
                                        {item.address}
                                    </li>
                                ))}
                            </>
                        )}
                        {selectedModalType === 'status' && (
                            <>
                                <li
                                    className={
                                        s[form.status === 'Все' ? 'selected-item' : 'list-item']
                                    }
                                    onClick={() => handleSelect('status', 'Все')}
                                >
                                    Выбрать все
                                </li>
                                {statusList.map((item) => (
                                    <li
                                        className={
                                            s[
                                                form.status === item.name
                                                    ? 'selected-item'
                                                    : 'list-item'
                                            ]
                                        }
                                        onClick={() => handleSelect('status', item.name)}
                                    >
                                        {item[language === 'ru' ? 'name_ru' : 'name_kz']}
                                    </li>
                                ))}
                            </>
                        )}
                    </ul>
                </div>
                <div className={s['buttons-wrap']}>
                    <button onClick={handleCloseModal} className={s['cancel-button']}>
                        {t('otmena')}
                    </button>
                    <button onClick={handleConfirmSelectClick} className={s['secondary-button']}>
                        {t('select')}
                    </button>
                </div>
            </CustomModal>
        </div>
    );
};

export default ReportPage;
